<template>
  <div>
    <table
      class="ui striped table"
      v-if="upcomingEvents && upcomingEvents.length > 0"
    >
      <tbody>
        <tr v-for="(event, index) in upcomingEvents" :key="index">
          <td class="ten wide" :class="{ 'text--deleted': event.deleted }">
            {{ event.timestamp }}
          </td>
          <td class="three wide right aligned">
            <span v-if="event.deleted" class="ui tiny label">GELÖSCHT</span>
            <span v-else-if="event.cancelled" class="ui orange tiny label"
              >ABGESAGT</span
            >
            <span v-else class="ui olive tiny label">GEPLANT</span>
          </td>
          <td class="one wide">
            <div class="ui simple dropdown item">
              <div class="ui icon basic mini button">
                <i class="ellipsis horizontal icon"></i>
              </div>
              <div class="menu">
                <div
                  class="small item"
                  @click="toggleCancelCourseEventModal(event, true)"
                  v-if="!event.cancelled && !event.deleted"
                >
                  Kurstermin absagen…
                </div>
                <div
                  class="item"
                  v-if="event.cancelled && !event.deleted"
                  @click="revertCancelCourseEvent(event)"
                >
                  Kursabsage stornieren
                </div>
                <div
                  class="item"
                  @click="deleteCourseEvent(event)"
                  v-if="!event.deleted"
                >
                  Kurstermin löschen
                </div>
                <div
                  class="item"
                  @click="revertDeleteCourseEvent(event)"
                  v-if="event.deleted"
                >
                  Kurstermin wiederherstellen
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else-if="!this.course.startAt"
      class="ui message info"
      style="margin-top: 5px"
    >
      <div class="header">Laufzeit des Kurses noch nicht eingetragen</div>
      <p>
        Sobald die Laufzeit des Kurses im Reiter „Kurs bearbeiten” eingepflegt
        und gespeichert wurde, können hier die kommenden Kurstermine verwaltet
        werden.
      </p>
    </div>
    <div v-else class="ui message info" style="margin-top: 5px">
      <div class="header">Keine kommenden Kurstermine vorhanden</div>
      <p>
        Alle Termine für diesen Kurs liegen in der Vergangenheit und können
        nicht mehr bearbeitet werden.
      </p>
    </div>

    <!-- Modal Delete -->
    <ModalCancelCourseEvent
      :isModalShowing.sync="isModalShowing"
      :isModalConfirmed.sync="isModalConfirmed"
      :course="course"
      :event="currentEvent"
      @modalConfirmed="deleteCourseEvent"
      @modalCancelled="toggleCancelCourseEventModal(false)"
    ></ModalCancelCourseEvent>
  </div>
</template>

<script>
import ModalCancelCourseEvent from "@/components/modals/ModalCancelCourseEvent.vue";

export default {
  name: "CourseEventList",
  props: ["events", "course", "event"],
  components: {
    ModalCancelCourseEvent,
  },
  data() {
    return {
      isModalShowing: false,
      isModalConfirmed: false,
      currentEvent: null,
    };
  },
  computed: {
    upcomingEvents() {
      if (!this.events || this.events.length === 0) {
        return [];
      }

      return this.events.filter((event) => {
        let eventStartDate = this.$moment(event.startAt);
        eventStartDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
        let today = this.$moment();
        if (this.$moment(eventStartDate).isAfter(today)) {
          return event;
        }
      });
    },
  },
  methods: {
    isUpcomingEvent() {
      return this.$moment(this.$moment(this.event.startAt.seconds)).isAfter(
        this.$moment
      );
    },
    async toggleCancelCourseEventModal(event, isShowing) {
      const nextEvent = await event;
      this.currentEvent = nextEvent ? nextEvent : null;
      this.isModalShowing = isShowing;
    },
    revertCancelCourseEvent(event) {
      this.$store.dispatch("event/revertCancelCourseEvent", {
        event: this.course,
        timestamp: event.startAt,
      });
    },
    deleteCourseEvent(event) {
      this.$store.dispatch("event/deleteCourseEvent", {
        event: this.course,
        timestamp: event.startAt,
      });
    },
    revertDeleteCourseEvent(event) {
      this.$store.dispatch("event/revertDeleteCourseEvent", {
        event: this.course,
        timestamp: event.startAt,
      });
    },
  },
};
</script>
