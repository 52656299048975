<template>
  <div>
    <div v-if="events && events.length > 0">
      <table class="ui striped selectable table" style="cursor: pointer">
        <tbody>
          <tr
            v-for="event in events"
            :key="event.id"
            :class="{ gray: event.visibility === 'draft' }"
            @click="show(event)"
          >
            <td class="nine wide" style="font-weight: 600">
              <router-link :to="{ name: 'course', params: { id: event.id } }">{{
                event.title
              }}</router-link>
            </td>
            <td class="two wide meta">
              <div class="ui label">
                <i class="eye icon"></i> {{ event.subscribers }}
              </div>

              <div class="ui label">
                <i class="mail icon"></i> {{ event.subscribersEmail }}
              </div>

              <div class="ui label">
                <i class="mobile alternate icon"></i> {{ event.subscribersSms }}
              </div>
            </td>
            <td class="one wide" style="width: 3%; padding: 0">
              <span
                class="ui empty circular small label"
                :class="{
                  gray: event.visibility === 'draft',
                  yellow: event.visibility === 'review',
                  olive: event.visibility === 'published',
                }"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="ui container"
      style="
        border: 1px dotted rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 15px;
      "
      v-else
    >
      <p>Keine Kurse gefunden.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseList",
  props: ["events"],
  methods: {
    show(event) {
      this.$router.push({ name: "course", params: { id: event.id } });
    },
  },
};
</script>
