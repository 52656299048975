import { render, staticRenderFns } from "./ModalCancelCourseEvent.vue?vue&type=template&id=33bfddd1&scoped=true&"
import script from "./ModalCancelCourseEvent.vue?vue&type=script&lang=js&"
export * from "./ModalCancelCourseEvent.vue?vue&type=script&lang=js&"
import style0 from "./ModalCancelCourseEvent.vue?vue&type=style&index=0&id=33bfddd1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33bfddd1",
  null
  
)

export default component.exports