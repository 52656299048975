<template>
  <form class="ui form" v-on:submit.prevent="cancelEvent">
    <div>
      <modal v-model="isModalShowing" @displayChanged="opened">
        <div slot="header" class="center">
          Absage vom Kurstermin
          <span v-if="event && event.startAt"
            >am {{ event.startAt | moment("L") }}</span
          >
        </div>
        <div class="content" slot="content">
          <div class="ui grid">
            <div class="row">
              <div class="four wide column">
                <h3 class="header">Begründung</h3>
                <span class="meta"
                  >Trage eine Begründung für die Absage ein.</span
                >
              </div>
              <div class="twelve wide column">
                <div class="field required">
                  <label>Begründung für Absage</label>
                  <textarea
                    rows="2"
                    placeholder="Bitte trage hier die Begründung ein, z.B. „Findet leider wegen schlechten Wetters nicht statt.”"
                    v-model.trim="form.cancelReason"
                    required
                  ></textarea>
                  <p class="meta" style="margin-top: 10px">
                    Diese Begründung erscheint auf der Startseite der
                    Internetseite im Beschreibungstext.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="four wide column">
                <h3 class="header">Benachrichtigungen</h3>
                <span class="meta"
                  >Wähle die Kanäle und verfasse die Nachrichten.</span
                >
              </div>
              <div class="twelve wide column">
                <div class="ui equal width grid" style="margin-right: 0">
                  <div class="column">
                    <div
                      class="ui checkbox"
                      style="display: block; margin-bottom: 1em"
                    >
                      <input
                        id="checkbox-email"
                        type="checkbox"
                        v-model="form.notifications.email"
                      />
                      <label for="checkbox-email"
                        >E-Mail-Benachrichtigung versenden
                        <span v-if="course"
                          ><br />({{ course.subscribersEmail }} Empfänger)</span
                        ></label
                      >
                    </div>
                    <div v-if="form.notifications.email">
                      <div class="field required">
                        <label>Text für E-Mail-Benachrichtigung</label>
                        <editor-content
                          :editor="editor"
                          class="editor"
                          style="font-size: 14px"
                          :required="form.notifications.email"
                        />
                      </div>
                    </div>
                  </div>
                  <!--
                  <div class="column">
                    <div
                      class="ui checkbox"
                      style="display: block; margin-bottom: 1em"
                    >
                      <input
                        id="checkbox-sms"
                        type="checkbox"
                        v-model="form.notifications.sms"
                      />
                      <label for="checkbox-sms"
                        >SMS-Benachrichtigung versenden
                        <span v-if="course"
                          ><br />({{ course.subscribersSms }} Empfänger)</span
                        ></label
                      >
                    </div>
                    <div class="field required" v-if="form.notifications.sms">
                      <label
                        >Text für SMS-Benachrichtigung (max. 160 Zeichen)</label
                      >
                      <textarea
                        rows="4"
                        v-model.trim="form.notifications.smsContent"
                        maxlength="160"
                        :required="form.notifications.smsContent"
                      ></textarea>
                    </div>
                    <div
                      class="ui message green"
                      style="margin-top: 5px"
                      v-if="event && !event.startAt.isSame(new Date(), 'day')"
                    >
                      <div class="header">
                        <i class="check circle sign icon"></i>Benachrichtigung
                        per SMS <u>nicht</u> empfohlen
                      </div>
                      <p>
                        Es ist noch ausreichend Zeit bis zum Kurstermin. Daher
                        ist eine Benachrichtigung per SMS nicht notwendig.
                      </p>
                    </div>
                    <div
                      class="ui message yellow"
                      style="margin-top: 5px"
                      v-if="event && event.startAt.isSame(new Date(), 'day')"
                    >
                      <div class="header">
                        <i class="warning sign icon"></i>Benachrichtigung per
                        SMS empfohlen
                      </div>
                      <p>
                        Der Kurstermin findet heute statt. Daher ist eine
                        Benachrichtigung per SMS zu empfehlen.
                      </p>
                    </div>
                  </div>
                  -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="actions">
          <div class="ui cancel button" @click="cancelLeavingViewModal">
            Abbrechen
          </div>
          <button
            class="ui primary right approve button"
            :class="{ loading }"
            type="submit"
          >
            Kurstermin absagen
          </button>
        </div>
      </modal>
    </div>
  </form>
</template>

<script>
import modal from "vue-semantic-modal";
import { Editor, EditorContent } from "tiptap";

export default {
  name: "ModalCancelCourseEvent",
  props: ["isModalShowing", "isModalConfirmed", "course", "event"],
  components: {
    modal,
    EditorContent,
  },
  data() {
    return {
      editor: null,
      loading: false,
      form: {
        cancelReason: null,
        notifications: {
          email: true,
          emailContent: null,
          sms: false,
          smsContent: null,
        },
      },
    };
  },
  beforeDestroy() {
    this.editor = null;
  },
  methods: {
    opened(state) {
      if (state === "opening") {
        if (this.editor) {
          this.form.notifications.smsContent = null;
          this.form.notifications.emailContent = null;
        }
        if (this.course && this.event) {
          const eventDate = this.$moment(this.event.startAt).format("L");
          this.form.notifications.smsContent = `Hallo, der Kurs ${this.course.title} findet am ${eventDate} wegen [...] nicht statt. Dein Team vom Skiklub Oestertal`;
          this.form.notifications.emailContent = `<p>Hallo,</p><p>der Kurs „${this.course.title}” findet am ${eventDate} wegen [...] nicht statt.</p><p>Weitere Informationen gibt es dazu auf unserer Internetseite https://skiklub-oestertal.de.</p><p>Viele Grüße,</p><p>Dein Team vom Skiklub Oestertal 1956 e.V.</p>`;
          this.editor = new Editor({
            linkUrl: null,
            linkMenuIsActive: false,
            content: this.form.notifications.emailContent,
            onUpdate: ({ getHTML }) => {
              this.form.notifications.emailContent = getHTML();
            },
          });
        }
      }
    },
    showLeavingViewModal() {
      this.$emit("update:isModalShowing", true);
      this.$emit("update:isModalConfirmed", false);
    },
    cancelLeavingViewModal() {
      this.editor = null;
      this.form = {
        cancelReason: null,
        notifications: {
          email: true,
          emailContent: null,
          sms: false,
          smsContent: null,
        },
      };

      this.$emit("update:isModalShowing", false);
      this.$emit("update:isModalConfirmed", false);
    },
    confirmLeavingViewModal() {
      this.cancelLeavingViewModal();
      this.$emit("cancelModalConfirmed");
      this.form = {
        cancelReason: null,
        notifications: {
          email: true,
          emailContent: null,
          sms: false,
          smsContent: null,
        },
      };
    },
    cancelEvent() {
      this.loading = true;
      this.$store
        .dispatch("event/cancelCourseEvent", {
          event: this.course,
          timestamp: this.event.startAt,
          reason: this.form.cancelReason,
        })
        .then(() =>
          this.$store.dispatch("notification/create", {
            type: "cancellation",
            event: this.course,
            payload: {
              email: this.form.notifications.email,
              emailContent: this.form.notifications.emailContent,
              sms: this.form.notifications.sms,
              smsContent: this.form.notifications.smsContent,
            },
          })
        )
        .then(() => this.confirmLeavingViewModal())
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 5px;
}

.dividing.header {
  margin-top: 2em;
}

.checkbox:hover {
  cursor: pointer;
}
</style>
