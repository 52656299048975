<template>
  <div class="ui container" style="margin-bottom: 3em">
    <div>
      <h1 class="ui header" style="float: left">Kurse</h1>

      <router-link
        :to="{ name: 'newCourse' }"
        class="ui primary button"
        style="margin-bottom: 1em; float: right; margin-right: 0"
      >
        Neuen Kurs erstellen
      </router-link>
      <div class="clear" style="clear: both"></div>
    </div>

    <CourseList :events="events" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CourseList from "@/components/lists/CourseList.vue";

export default {
  name: "Courses",
  components: {
    CourseList,
  },
  computed: {
    ...mapGetters({
      events: "event/recurring",
    }),
  },
};
</script>
