<template>
  <div class="ui container" style="margin-bottom: 2em">
    <!-- [START] Breadcrumb -->
    <div style="margin-bottom: 1em">
      <router-link :to="{ name: 'courses' }">
        <i class="arrow left icon" />
        <strong>Alle Kurse</strong>
      </router-link>
      <h1 style="margin-top: 6px">
        {{ event && event.title ? "Kurs bearbeiten" : "Neuen Kurs erstellen" }}
      </h1>
    </div>
    <!-- [END] Breadcrumb -->

    <CourseForm v-if="event" :event="event" @isDirty="isDirty" />

    <!-- [START] Modal Cancel -->
    <ModalCancel
      :isModalShowing.sync="isModalShowing"
      :isModalConfirmed.sync="isModalConfirmed"
      @isDirty="isDirty(index, ...arguments)"
      @cancelModalConfirmed="modalConfirmed"
    ></ModalCancel>
    <!-- [END] Modal Cancel -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CourseForm from "@/components/forms/CourseForm.vue";
import ModalCancel from "@/components/modals/ModalCancel.vue";

export default {
  name: "Course",
  props: ["id"],
  components: {
    CourseForm,
    ModalCancel,
  },
  data() {
    return {
      isModalShowing: false,
      isModalConfirmed: false,
      isFormDirty: false,
      cancelRoute: null,
    };
  },
  computed: {
    ...mapGetters({
      eventById: "event/eventById",
    }),
    event() {
      if (!this.id) {
        return {
          title: null,
          content: null,
          startAt: null,
          endAt: null,
          time: null,
          visibility: "draft",
        };
      }
      return this.eventById(this.id);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isModalConfirmed || !this.isFormDirty) {
      next();
    } else {
      this.isModalShowing = true;
      this.isModalConfirmed = false;
      this.cancelRoute = to;
      next(false);
    }
  },
  methods: {
    isDirty(isDirty) {
      this.isFormDirty = isDirty;
    },
    modalConfirmed() {
      this.isModalConfirmed = true;
      this.isModalShowing = false;
      this.$router.push(this.cancelRoute);
    },
  },
};
</script>
