<template>
  <form
    class="ui form"
    :class="{ loading: loading }"
    @submit.prevent="saveEvent"
    @change="markFormAsDirty"
  >
    <div class="ui secondary pointing menu" style="margin-bottom: 2em">
      <a class="active item" data-tab="info"
        ><i class="edit outline icon"></i> Kursinformationen</a
      >
      <a class="item" data-tab="dates"
        ><i class="calendar alternate outline icon"></i> Kurstermine</a
      >
    </div>
    <div class="ui active tab" data-tab="info">
      <div class="ui stackable grid">
        <!-- [START] Title, Content -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Stammdaten</h3>
            <span class="meta"
              >Bearbeite hier den Titel und die Beschreibung des Kurses.</span
            >
          </div>
          <div class="twelve wide column">
            <!-- [START] Title -->
            <div class="field required">
              <label>Titel des Kurses</label>
              <input
                type="text"
                placeholder="Titel des Kurses"
                name="title"
                :value="event ? event.title : null"
                style="padding: 8px 15px; font-size: 24px; line-height: 36px"
              />
            </div>
            <!-- [END] Title -->
            <!-- [START] Content -->
            <div class="field required">
              <label>Beschreibung des Kurses</label>
              <form-editor :editor="editor" />
            </div>
            <!-- [END] Content -->
            <!-- [START] Excerpt -->
            <div class="field required">
              <label>Auszug</label>
              <editor-content :editor="editorExcerpt" class="editor" />
              <p class="meta" style="margin-top: 4px">
                Der Auszug erscheint in der Seitenleiste auf der Startseite und
                sollte max. 250 Zeichen lang sein.
              </p>
            </div>
            <!-- [END] Excerpt -->
          </div>
        </div>
        <!-- [END] Title, Content -->
        <!-- [START] Dates, Intervals -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Zeiten</h3>
            <span class="meta"
              >Trage die Laufzeit des Kurses und die entsprechenden
              Wiederholungen ein.</span
            >
          </div>
          <div class="twelve wide column">
            <!-- [START] Date Configuration -->
            <div class="three fields">
              <!-- [START] Start Date -->
              <div class="field required">
                <label>Ersten Kurstermin</label>
                <div class="ui field">
                  <input
                    type="date"
                    name="startAt"
                    placeholder="Datum wählen…"
                    :value="startAt ? startAt : null"
                    :min="startAt ? startAt : today"
                    required
                  />
                </div>
              </div>
              <!-- [END] Start Date -->
              <!-- [START] End Date -->
              <div class="field required">
                <label>Letzter Kurstermin</label>
                <div class="ui field">
                  <div class="ui labeled input">
                    <input
                      type="date"
                      name="endAt"
                      placeholder="Datum wählen…"
                      steps="1"
                      :min="startAt ? startAt : null"
                      :value="endAt"
                      required
                    />
                  </div>
                </div>
              </div>
              <!-- [END] End Date -->
              <!-- [START] Time -->
              <div class="field required">
                <label>Uhrzeit</label>
                <div class="ui field">
                  <div class="ui labeled input">
                    <input
                      type="time"
                      name="time"
                      placeholder="Uhrzeit wählen…"
                      steps="1"
                      :value="time ? time : null"
                      required
                    />
                  </div>
                </div>
              </div>
              <!-- [END] Time -->
            </div>
            <div class="fields">
              <!-- [START] Recurring Pattern Period  -->
              <div class="eight wide field required">
                <label>Der Kurs findet an diesen Wochentag(en) statt …</label>
                <select
                  name="weekdays"
                  multiple=""
                  class="ui dropdown fluid required"
                  id="recurrenceWeekdays"
                  required
                >
                  <option value="">Wochentage wählen…</option>
                  <option value="1">Montags</option>
                  <option value="2">Dienstags</option>
                  <option value="3">Mittwochs</option>
                  <option value="4">Donnerstags</option>
                  <option value="5">Freitags</option>
                  <option value="6">Samstags</option>
                  <option value="0">Sonntags</option>
                </select>
              </div>
              <!-- [END] Recurring Pattern Period  -->
              <!-- [START] Recurring Pattern -->
              <div class="eight wide field">
                <div class="field required">
                  <label>… und wiederholt sich …</label>
                  <select id="recurrencePeriod" class="ui dropdown" required>
                    <option value="">Intervall wählen…</option>
                    <option value="weekly">… jede Woche.</option>
                    <!--
                    <option value="second-weekly" disabled
                      >… jede zweite Woche.</option
                    >
                    <option value="monthly" disabled>… jeden Monat.</option>
                    -->
                  </select>
                </div>
                <!-- [END] Recurring Pattern  -->
              </div>
            </div>
          </div>
          <!-- [END] Date Configuration -->
        </div>
        <!-- [END] Dates, Intervals -->

        <!-- [START] Layout -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Titelbild</h3>
            <span class="meta"
              >Wähle ein Titelbild für diesen Beitrag aus.</span
            >
          </div>
          <div class="six wide column">
            <!-- [START] Header Image -->
            <div class="field" v-if="this.event">
              <label>Titelbild</label>
              <ImageTitleSelect
                :sourceImage="this.event.imageUrl"
                :targetImage="this.imageUploadPath"
                v-on:setImage="setImage"
                v-on:setImageUploading="setImageUploading"
                v-on:deleteImage="deleteImage"
              />
              <p class="meta" style="margin-top: 10px">
                Kein Bild? Kostenlose Bilder findest du z.B. auf
                <a href="https://unsplash.com/" target="_blank">Unsplash</a>.
              </p>
            </div>
            <!-- [END] Header Image -->
          </div>
        </div>
        <!-- [End] Layout -->

        <!-- [START] Status -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Status</h3>
            <span class="meta">Lege die Sichtbarkeit des Kurses fest.</span>
          </div>
          <div class="five wide column">
            <div class="field required">
              <label>Status</label>
              <select id="visibility" class="ui dropdown" required>
                <option value="">Status wählen …</option>
                <option value="draft">
                  <span class="ui empty circular label gray"></span> Entwurf
                </option>
                <option value="review">
                  <span class="ui empty circular label yellow"></span>
                  Ausstehende Freigabe
                </option>
                <option value="published">
                  <span class="ui empty circular label olive"></span>
                  Veröffentlicht
                </option>
              </select>
            </div>
          </div>
          <!-- [END] Status -->
        </div>
        <div
          class="row"
          style="
            border-top: 1px solid rgba(34, 36, 38, 0.15);
            padding-top: 20px;
            margin-top: 20px;
          "
        >
          <div class="sixteen wide column">
            <div style="float: left" v-if="event.id">
              <button
                class="ui basic button red fluid"
                style="margin-bottom: 1em; margin-right: 0; cursor: pointer"
                @click.prevent="showDeleteModal"
                v-if="
                  user &&
                  user.roles &&
                  user.roles.admin &&
                  event.visibility === 'published' &&
                  event.subscribers > 0
                "
              >
                Kurs löschen
              </button>
              <button v-else class="ui basic button red fluid" disabled>
                Kurs löschen nicht möglich
              </button>
            </div>
            <div style="float: right">
              <button
                type="submit"
                class="ui primary button fluid"
                :disabled="!isDirty"
                style="
                  margin-bottom: 1em;
                  float: right;
                  margin-right: 0;
                  cursor: pointer;
                "
              >
                {{ event ? "Speichern" : "Erstellen" }}
              </button>
            </div>
          </div>
          <!-- [START] Buttons -->
        </div>
      </div>
    </div>
    <div class="ui tab" data-tab="dates">
      <div class="ui stackable grid">
        <!-- [START] Upcoming events -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Kurstermine</h3>
            <span class="meta">Verwalte hier die nächsten Kurstermine.</span>
          </div>
          <div class="twelve wide column">
            <CourseEventList :events="nextEvents" :course="event" />
          </div>
        </div>
        <!-- [END] Upcoming events -->
      </div>
    </div>

    <!-- Modal Delete -->
    <ModalDelete
      :isModalShowing.sync="isModalShowing"
      :isModalConfirmed.sync="isModalConfirmed"
      @modalConfirmed="deleteEvent"
      @modalCancelled="modalCancelled"
    ></ModalDelete>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { Editor, EditorContent } from "tiptap";
import {
  Blockquote,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Underline,
  History,
  Heading,
} from "tiptap-extensions";
import FormEditor from "@/components/forms/FormEditor.vue";
import CourseEventList from "@/components/lists/CourseEventList.vue";
import ModalDelete from "@/components/modals/ModalDelete.vue";
import ImageTitleSelect from "@/components/ImageTitleSelect.vue";

export default {
  name: "CourseForm",
  props: ["event"],
  components: {
    EditorContent,
    CourseEventList,
    ModalDelete,
    ImageTitleSelect,
    FormEditor,
  },
  data() {
    return {
      loading: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          new Link(),
          new Underline(),
          new History(),
          new Heading({ levels: [1, 2, 3] }),
        ],
        linkUrl: null,
        linkMenuIsActive: false,
        onUpdate: ({ getJSON, getHTML }) => {
          if (
            this.draftPost &&
            this.draftPost.contentHtml &&
            this.draftPost.contentHtml != this.event.content
          ) {
            this.markFormAsDirty();
          }
          this.draftPost.contentJson = getJSON();
          this.draftPost.contentHtml = getHTML();
        },
        content: this.event.content,
      }),
      editorExcerpt: new Editor({
        linkUrl: null,
        linkMenuIsActive: false,
        onUpdate: ({ getJSON, getHTML }) => {
          if (
            this.draftPostExcerpt &&
            this.draftPostExcerpt.contentHtml &&
            this.draftPostExcerpt.contentHtml != this.event.excerpt
          ) {
            this.markFormAsDirty();
          }
          this.draftPostExcerpt.contentJson = getJSON();
          this.draftPostExcerpt.contentHtml = getHTML();
        },
        content: this.event.excerpt,
      }),
      isDirty: false,
      isModalShowing: false,
      isModalConfirmed: false,
      nextEvents: null,
      imageUploadPath: null,
      imageUploading: false,
      draftPost: {
        contentJson: null,
        contentHtml: null,
        contentDate: null,
      },
      draftPostExcerpt: {
        contentJson: null,
        contentHtml: null,
        contentDate: null,
      },
    };
  },
  watch: {
    event: function () {
      this.generateNextEvents();
    },
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
    today() {
      return this.$moment.utc(this.$moment()).local().format("YYYY-MM-DD");
    },
    startAt() {
      if (!this.event || !this.event.startAt) {
        return null;
      }
      return this.$moment
        .utc(this.event.startAt.seconds * 1000)
        .local()
        .format("YYYY-MM-DD");
    },
    time() {
      if (!this.event || !this.event.startAt) {
        return null;
      }
      return this.$moment
        .utc(this.event.startAt.seconds * 1000)
        .local()
        .format("HH:mm");
    },
    endAt() {
      if (!this.event || !this.event.endAt) {
        return null;
      }
      return this.$moment
        .utc(this.event.endAt.seconds * 1000)
        .local()
        .format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.initEditor();

    if (this.event && this.user) {
      if (this.event.startAt) {
        this.draftPost.contentDate = this.$moment(
          this.event.startAt.seconds
        ).format("YYYY-MM-DDTHH:mm");
      }

      $("#recurrencePeriod").dropdown(
        "set selected",
        this.event && this.event.recurrencePeriod
          ? this.event.recurrencePeriod
          : null
      );
      $("#recurrenceWeekdays").dropdown(
        "set selected",
        this.event && this.event.recurrenceWeekdays
          ? this.event.recurrenceWeekdays
          : null
      );
      $("#visibility").dropdown(
        "set selected",
        this.event && this.event.visibility ? this.event.visibility : null
      );
      this.imageUploadPath = this.event.imageUrl;
    }

    $(".ui.dropdown").dropdown();
    $(".menu .item").tab();
    const self = this;
    setTimeout(function () {
      self.generateNextEvents();
    }, 100);
  },
  methods: {
    initEditor() {
      this.isDirty = false;
    },
    async generateNextEvents() {
      if (
        !this.event ||
        !this.event.startAt ||
        !this.event.endAt ||
        !this.event.recurrenceWeekdays
      ) {
        return;
      }
      var startDate = this.$moment(this.event.startAt.toDate());
      var endDate = this.$moment(this.event.endAt.toDate());
      const recurrenceWeekdays = $.trim(this.event.recurrenceWeekdays);

      var condition = this.$moment()
        .recur({
          start: startDate,
          end: endDate,
        })
        .every(recurrenceWeekdays.split(",").map(Number))
        .daysOfWeek();

      var nextDates = condition.all();
      this.nextEvents = [];
      nextDates.forEach(async (nextDate) => {
        // Check if next date is listed in the exception list and won't be displayed
        let exception;
        if (this.event.exceptions && this.event.exceptions.length > 0) {
          exception = this.event.exceptions.find(
            (exception) =>
              this.$moment(exception).toISOString() ===
              this.$moment(nextDate).toISOString()
          );
        }
        let cancelledEvent;
        for (const key in this.event.cancelled) {
          if (key == this.$moment(nextDate).toISOString()) {
            cancelledEvent = {
              startAt: nextDate,
              timestamp: this.$moment(nextDate).format("dddd, DD. MMMM YYYY"),
              reason: this.event.cancelled[key],
              cancelled: true,
              deleted: exception ? true : false,
            };
          }
        }
        if (!cancelledEvent) {
          this.nextEvents.push({
            startAt: nextDate,
            timestamp: this.$moment(nextDate).format("dddd, DD. MMMM YYYY"),
            deleted: exception ? true : false,
          });
        } else {
          this.nextEvents.push(cancelledEvent);
        }
      });
    },
    setImage(uploadPath) {
      this.imageUploadPath = uploadPath;
      this.isDirty = true;
    },
    setImageUploading(uploading) {
      this.imageUploading = uploading;
    },
    deleteImage() {
      this.imageUploadPath = null;
      this.isDirty = true;
    },
    saveEvent() {
      const title = $.trim($("[name=title]").val());
      const content = this.draftPost.contentHtml
        ? $.trim(this.draftPost.contentHtml)
        : this.event.content;
      const excerpt = this.draftPostExcerpt.contentHtml
        ? $.trim(this.draftPostExcerpt.contentHtml)
        : this.event.excerpt;
      let startAt = $.trim($("[name=startAt]").val());
      const endAt = $.trim($("[name=endAt]").val());
      let time = $.trim($("[name=time]").val());
      const visibility = $.trim($("#visibility").dropdown("get value"));
      const recurrencePeriod = $.trim(
        $("#recurrencePeriod").dropdown("get value")
      );
      const recurrenceWeekdays = $.trim(
        $("#recurrenceWeekdays").dropdown("get value")
      );

      // Add time to given start timestamp
      time = this.$moment(time, "HH:mm");
      startAt = this.$moment(startAt)
        .add(time.hour(), "hour")
        .add(time.minute(), "minute");

      const payload = {
        title: title,
        content: content,
        excerpt: excerpt,
        startAt: startAt,
        endAt: this.$moment(endAt),
        visibility: visibility,
        recurrencePeriod: recurrencePeriod,
        recurrenceWeekdays: recurrenceWeekdays.split(","),
        imageUrl: this.imageUploadPath || null,
      };

      this.loading = true;
      if (!this.event.id) {
        const self = this;

        const payloadWithDefaults = {
          ...payload,
          isRecurring: true,
          subscribers: 0,
          subscribersEmail: 0,
          subscribersSms: 0,
        };
        return this.$store
          .dispatch("event/create", {
            payload: payloadWithDefaults,
          })
          .then((result) => {
            this.isDirty = false;
            this.$emit("isDirty", this.isDirty);

            this.isModalConfirmed = true;
            self.$router.push({ name: "course", params: { id: result.id } });
          })
          .catch((error) => console.log("Error creating event", error))
          .finally(() => (this.loading = false));
      } else {
        return this.$store
          .dispatch("event/update", {
            eventId: this.event.id,
            payload: payload,
          })
          .then(() => {
            this.isDirty = false;
            this.$emit("isDirty", this.isDirty);
          })
          .catch((error) => console.log("Error updating event", error))
          .finally(() => (this.loading = false));
      }
    },
    deleteEvent() {
      const self = this;
      this.loading = true;

      this.isDirty = false;
      this.$emit("isDirty", this.isDirty);
      this.isModalConfirmed = true;

      this.$store
        .dispatch("event/delete", { eventId: this.event.id })
        .then(() => {
          self.$router.push({ name: "courses" });
        })
        .catch((error) => console.log("Error deleting course", error))
        .finally(() => (this.loading = false));
    },
    markFormAsDirty() {
      this.isDirty = true;
      this.$emit("isDirty", this.isDirty);
    },
    showDeleteModal() {
      this.isModalConfirmed = false;
      this.isModalShowing = true;
    },
    modalCancelled() {
      this.isModalConfirmed = false;
      this.isModalShowing = false;
    },
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 5px;
}
</style>
